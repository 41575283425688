.navigation {
    position: fixed;
    top: 4vh;
    right: 4vh;
    padding: 1rem;
    z-index: 1000;
  }
  
  .nav-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  /* Desktop menu */
  .desktop-menu {
    display: flex;
    gap: 1rem;
  }
  
  .nav-item {
    font-weight: bold;
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .nav-item.active {
    color: white;
    background-color: black;
  }
  
  /* Mobile menu icon */
  .nav-menu-icon {
    display: none;
    cursor: pointer;
  }
  
  .hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
  }
  
  /* Mobile dropdown */
  .mobile-menu-dropdown {
    position: absolute;
    top: 60px;
    right: 1rem;
    background-color: white;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .desktop-menu {
      display: none;
    }
    .nav-menu-icon {
      display: block;
    }
  }
  