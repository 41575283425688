.abc {
  position: fixed;
  top: 30vh;
  display: flex;
  width: 90vw;
  height: 30vh;
  flex-direction: column;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "Bricolage Grotesque";
}

.abcLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
