.annotationContainer {
  position: fixed;
  top: 20%;
  left: 4vh;
  display: flex;
  width: 80vw;
  height: 60vh;
  flex-direction: column;
  z-index: 1000;
  font-size: 1.5vw;
  /* flex-direction: column;
  align-items: flex-start;
  background-color: aquamarine; */
}

.text {
  position: absolute;
  width: 100%;
  height: 60vh;
  top: 0;
  left: 0;
  font-size: 1.5vw; /* Default font size */
  font-family: "Bricolage Grotesque";
}

.wordSpan {
  padding: 0 4px;
  width: 100%;
}
span {
  font-family: "Bricolage Grotesque";
  display: inline-block;
  text-align: center; /* Center the character in the block */
  margin: 0 1px; /* Small gap between characters */
}

.line {
  top: 0;
  left: 0;
  /* position: relative; */
  /* width: 50vw; */
  /* background-color: yellow; */
  width: 100%;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}
.wordContainer {
  font-weight: normal;
  background-color: red;
  width: 100%;
  font-size: 1.5vw;
}

.wordC {
  will-change: transform, opacity;
  display: inline-block;
  margin: 0 5px;
  font-size: 1.5vw;
}
@media (orientation: landscape) {
  .wordC {
    font-size: clamp(15px, 4vw, 32px);
  }
  .annotationContainer {
    width: 50%;
  }
}

@media (orientation: portrait) {
  .wordC {
    font-size: clamp(25px, 3vw, 38px); /* Adjusted for landscape */
  }
  .annotationContainer {
    width: 75%;
  }
}
