.titleContainer {
  z-index: 1000;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 80vw;
  height: 60vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: "green";
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.titleQuote {
  top: 35%;
  left: 4vh;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  background-color: "green";
  font-size: 1.5vw;
  font-family: "Bricolage Grotesque";
  z-index: 100;
}
/* Additional media queries can still be used if needed */
@media (orientation: landscape) {
  .titleQuote {
    font-size: clamp(25px, 6vw, 50px);
  }
  .titleContainer {
    width: 50%;
  }
}

@media (orientation: portrait) {
  .titleQuote {
    font-size: clamp(32px, 5vw, 45px);
  }

  .titleContainer {
    width: 75%;
  }
}
