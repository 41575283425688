.footerContainer {
  position: fixed;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  justify-content: space-around; /* Adjusts children horizontally with space around them */
  height: 15%;
  bottom: 0;
  z-index: 100;
}

.button {
  background-color: #f5f5f5;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  z-index: 100;
}

.leftButton,
.rightButton {
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures that the icon and text are centered relative to each other */
  text-align: center; /* Aligns the text in the center under the icon */
}

.leftButton img,
.rightButton img {
  display: block; /* Ensures the image does not have inline whitespace issues */
  margin-bottom: 8px; /* Space between the icon and the text */
}

.leftButton {
  position: absolute; /* You need to define position as absolute to use left/right properly */
  left: 5vh;
}

.rightButton {
  position: absolute; /* You need to define position as absolute to use left/right properly */
  right: 5vh;
}
.button a {
  /* Additional styles to make Links look like the text on the button */
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 10px; /* Adjust padding as needed */
}
