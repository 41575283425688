.contact {
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px;
  margin: auto;
  position: absolute;
  font-family: "Bricolage Grotesque";
  padding: 20px;
  box-sizing: border-box;
}

.about {
  top: 12%;
  left: 4vh;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  position: absolute;
  font-family: "Bricolage Grotesque";
  font-size: clamp(19px, 4vw, 30px);
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  margin-bottom: 10px;
  border-width: 1.5px;
  min-height: 40px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.submit-btn {
  padding: 10px;
  background-color: rgba(145, 254, 175, 255);
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.status-message {
  margin-top: 10px;
  text-align: center;
}

/* Media queries for mobile responsiveness */
@media (max-width: 600px) {
  .contact {
    width: 100%;
    padding: 15px;
  }

  .input-field {
    font-size: 16px;
  }

  .submit-btn {
    font-size: 16px;
  }
}

h1 {
  font-size: 1.5em; /* Increased font size */
  margin-bottom: 10px;
  text-align: left;
}

p {
  font-size: 1.2em; /* Increased font size */
  margin-bottom: 20px;
  text-align: left;
}
